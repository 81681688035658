import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { CSP_NONCE } from '@angular/core';

if (environment.env === `prod`) {
  Sentry.init({
    release: 'patient@' + environment.version,
    environment: environment.env,
    integrations: [
      Sentry.browserProfilingIntegration(), // Captura dados de performance do navegador
      Sentry.browserTracingIntegration(), // Rastreamento de transações
      Sentry.replayIntegration({
        maskAllText: true, // Ofusca textos para privacidade
        blockAllMedia: true // Bloqueia captura de mídias (imagens, vídeos, etc.)
      }),
      Sentry.httpClientIntegration(), // Captura erros e métricas de requisições HTTP
      Sentry.captureConsoleIntegration({
        levels: ['error', 'warn'] // Captura logs do console (erros e avisos)
      }),
      Sentry.contextLinesIntegration(), // Adiciona mais contexto às stack traces
      Sentry.dedupeIntegration() // Evita duplicação de erros
    ],
    sendDefaultPii: true, // Envia informações pessoais identificáveis (cuidado em produção)
    attachStacktrace: true, // Inclui stack traces em todos os erros
    normalizeDepth: 10, // Captura objetos profundos (até 10 níveis)
    debug: !environment.production, // Ativa logs detalhados no console durante o desenvolvimento

    // Performance Monitoring
    tracesSampleRate: environment.production ? 0.2 : 1.0, // 20% em produção, 100% em desenvolvimento
    tracesSampler: (samplingContext) => {
      // Exemplo: Aumentar a taxa de amostragem para transações críticas
      if (samplingContext.transactionContext.name.includes('critical')) {
        return 1.0; // Captura 100% das transações críticas
      }
      return environment.production ? 0.2 : 1.0;
    },

    // URLs para rastreamento distribuído
    tracePropagationTargets: [
      environment.loginRedirect,
      // Adicione outras URLs importantes aqui
    ],

    // Session Replay
    replaysSessionSampleRate: environment.production ? 0.1 : 1.0, // 10% em produção, 100% em desenvolvimento
    replaysOnErrorSampleRate: 1.0 // Captura 100% das sessões com erros
  });
}

platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [{
    provide: CSP_NONCE,
    useValue: globalThis.myRandomNonceValue
  }]
})
  .catch(err => console.error(err));

